exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-book-index-js": () => import("./../../../src/pages/book/index.js" /* webpackChunkName: "component---src-pages-book-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-service-centre-index-js": () => import("./../../../src/pages/service-centre/index.js" /* webpackChunkName: "component---src-pages-service-centre-index-js" */),
  "component---src-pages-service-centre-pricing-index-js": () => import("./../../../src/pages/service-centre/pricing/index.js" /* webpackChunkName: "component---src-pages-service-centre-pricing-index-js" */),
  "component---src-pages-service-centre-showcase-index-js": () => import("./../../../src/pages/service-centre/showcase/index.js" /* webpackChunkName: "component---src-pages-service-centre-showcase-index-js" */),
  "component---src-templates-marketing-js": () => import("./../../../src/templates/marketing.js" /* webpackChunkName: "component---src-templates-marketing-js" */)
}

